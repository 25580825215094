import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { propertyByPlanNumber } from '../redux/actions/PropertyAction';
import { toast } from 'react-toastify';

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planNumState, setPlanNumState] = useState("")


  const propertyPlan = async () => {
    await dispatch(propertyByPlanNumber({ plan_number: planNumState })).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message)
        navigate(`/propertyList/${res?.payload?.data?._id}`)
      } else {
        toast.error(res?.payload?.message)
      }
    })
  }

  console.log("planNumState0", planNumState)

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-flex flex-column justify-content-between">
              <div className="looking-plan">
                <h3>Looking For Plan?</h3>
                <div className="mb-3 mt-4">
                  <input type="text" className="form-control" placeholder="Search by plan #" onChange={(e) => setPlanNumState(e.target.value)} />
                  <button type="button" className="btn btn-secondary" onClick={propertyPlan}>Search</button>
                </div>
              </div>
              <div className="logo-footer">
                <img src="./images/intro.png" alt="" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-4 ">
                  <h5>Find your plan</h5>
                  <ul>
                    <li><a href="">House Plan</a></li>
                    <li><a href="">HOT Plans!</a></li>
                    <li><a href="">New</a></li>
                    <li><a href="">Styles</a></li>
                    <li><a href="">Collections</a></li>
                    <li><a href="">Client Builds</a></li>
                    <li><a href="">Recently Sold</a></li>
                    <li><a href="">Most Popular</a></li>
                    <li><a href="">By US State</a></li>
                    <li><a href="">By CA Province</a></li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h5>About</h5>
                  <ul>
                    <li><a href="">Our Story</a></li>
                    <li><a href="">Pricing</a></li>
                    <li><a href="">Reviews</a></li>
                    <li><a href="">Testimoials</a></li>
                    <li><a href="">Copyright</a></li>
                    <li><a href="">FAQ</a></li>
                    <li><a href="">Contact Us</a></li>
                    <li><a href="">blogs</a></li>
                    <li><a href="">Return Policy </a></li>
                    <li><a href="">Shipping Policy</a></li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h5>Services</h5>
                  <ul>
                    <li><a href="">What’s Included</a></li>
                    <li><a href="">Cost-to-Build Report</a></li>
                    <li><a href="">Modifications</a></li>
                    <li><a href="">Custom House Plans</a></li>
                    <li><a href="">For Builder</a></li>
                    <li><a href="">For Real Estate Agents</a></li>
                    <li><a href="">Become an AD Designer</a></li>
                    <li><a href="">Become an Affiliate</a></li>
                    <li><a href="">Photo removal request </a></li>
                    <li><a href="">Change to existing orders</a></li>
                    <li><a href="">Home Products & Services</a></li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="contactus">
                    <h5>Contact us</h5>
                    <div className="details-others">
                      <div className="call-info">
                        <img src="./images/Component 1.svg" alt="" />
                        <a href="tel: +234 8109824535" >+234 8109824535</a>

                      </div>
                      <div className="call-info">
                        <img src="./images/mail_1.svg" alt="" />
                        <a href="mailto: rholzsquad.studio@gmail.com" >rholzsquad.studio@gmail.com</a>

                      </div>
                      <div className="call-info">
                        <img src="./images/where_to_vote.svg" alt="" />
                        <p>NIGERIA / FLORIDA</p>

                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6">
                  <div className="subscription">
                    <h5>Subscription</h5>
                    <div className="input-subs">
                      <div className="mb-3">
                        <input type="email" className="form-control" placeholder="E-mail" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="copyryt">
                    <p>© 2024 — Copyright</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="social-media">
                    <a href=""><img src="./images/item.svg" alt="" /></a>
                    <a href=""><img src="./images/item_1.svg" alt="" /></a>
                    <a href=""><img src="./images/item_2.svg" alt="" /></a>
                    <a href=""><img src="./images/item_3.svg" alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
