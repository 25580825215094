import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utilis/commonFunction";

const listPropertyAction = createAsyncThunk(
    'list/property', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/properties${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const detailsPropertyAction = createAsyncThunk(
    'details/property', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/property/${reqBody.id}`, reqBody.formData)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const propertyByPlanNumber = createAsyncThunk(
    'property/plan-no', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/property`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export {
    listPropertyAction,
    detailsPropertyAction,
    propertyByPlanNumber
}