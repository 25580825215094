import { createSlice } from "@reduxjs/toolkit";
import { detailsPropertyAction, listPropertyAction } from "../actions/PropertyAction";
const initialState = {
    propertyList: [],
    propertyDetails: {}
}

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listPropertyAction.fulfilled, (state, action) => {
                state.loading = false;
                state.propertyList = action?.payload?.data;
            })
            .addCase(detailsPropertyAction.fulfilled, (state, action) => {
                state.loading = false;
                state.propertyDetails = action?.payload?.data;
            })
    },
});

export default propertySlice.reducer;