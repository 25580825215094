import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './fonts/stylesheet.css';
import './assets/css/style.css';
import { Provider } from "react-redux";
import { Toaster } from 'react-hot-toast';
import store from './redux/store';
import "pure-react-carousel/dist/react-carousel.es.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster position="top-right" reverseOrder={false} />
    <Provider store={store}>
      <App />
    </Provider>


  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
