const constructQueryString = (obj) => {
    if (obj) {
        const queryString = Object.keys(obj)
            .filter(key => obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join('&');
        return queryString;
    } else {
        return ""
    }
}

export {
    constructQueryString
}