import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { loginAction, resetpasswordAction } from '../redux/actions/AuthAction';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = await dispatch(resetpasswordAction(values));
      if(data.payload.message != "User not found"){
        toast.success(`${data?.payload?.message} and your Otp is ${data?.payload?.data?.otp}`)
        navigate(`/verifyOtp?otp=${data?.payload?.data?.otp}&email=${values.email}`)
      } else {
        toast.error(data.payload.message)
      }
      
    },
  });

  return (
    <Layout>
      <section className="login-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="pt-content">
                <ul>
                  <li>Save searches and favorite plans.</li>
                  <li>Access your account from any device including favorites, cart, and order history.</li>
                  <li>Sign up for our newsletter for coupons and sales.</li>
                  <li>No password required.</li>
                </ul>
                <div className="text-end pb-4">
                  <img src="./images/house-bg.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login-form">
                <h2>Reset Password</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Email Address"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <button type="submit" className="btn btn-primary btn-login">Submit</button>
                </form>
                <div className="lost-psd text-center">
                  <Link to={`/SignInEmail`}>Click here to skip the password and sign in with just your email and a link</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ResetPassword;
