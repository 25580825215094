import React from 'react'
import Layout from './Layout'
import checkoutcart from "../assets/images/checkoutcart.png"

const Checkout = () => {
    return (
        <>
            <Layout>
                <div className='container'>
                    <div className="top-heading mt-5">
                        <h2>Checkout</h2>
                    </div>
                </div>
                <section className='carts '>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-9 mx-auto'>
                                <div className='review-wrap check-wrap'>
                                    <div className="wraps">
                                        <div className='d-flex top-h'>
                                            <strong>Item 1</strong>
                                            <span>Edit</span>
                                        </div>
                                        <div className='d-flex'>
                                            <img src={checkoutcart} />
                                            <p>Plan 135215GRA 3-Bed Barndominium-Style House Plan Under 1900 Square Feet with Extra-Deep 2-Car Garage</p>
                                        </div>
                                        <hr className='w-100'></hr>
                                        <div className='email-details'>
                                            <div className='d-flex justify-content-between'>
                                                <strong>Email To</strong>
                                                <span className='edit-link'>Edit</span>
                                            </div>
                                            <div className='d-flex justify-content-between '>

                                                <span>Name:</span>
                                                <strong>ABC</strong>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>Email Address</span>
                                                <strong>ABC</strong>

                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>Conact Number </span>
                                                <strong>ABC</strong>

                                            </div>
                                            <div className='d-flex justify-content-between'>

                                                <span>Address</span>
                                                <strong> ABC</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cart-content check-acrt'>

                                        <hr className='divider'></hr>
                                        <div className='subtotal check-total'>
                                            <div className='plan'>
                                                <h5>Plan 135215GRA </h5>
                                                <div className='pdf'>
                                                    <span>PDF - Single - Build</span>
                                                    <strong>$25</strong>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className='plan'>
                                                <h5>Plan 135215GRA  </h5>
                                                <div className='pdf'>
                                                    <span>PDF - Single - Build</span>
                                                    <strong>$25</strong>
                                                </div>
                                            </div>
                                            <hr className='mt-5'></hr>
                                            <div className='cart-total'>
                                                <div className='tax'>
                                                    <span>Tax</span>
                                                    <strong>$25</strong>
                                                </div>
                                                <div className='tax'>
                                                    <span>Subtotal </span>
                                                    <strong>$25</strong>
                                                </div>
                                                <div className='tax final-total'>
                                                    <span>Total </span>
                                                    <strong>$8524</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='order-summary'>
                                    <h4>Order Summary</h4>
                                    <hr className='w-100'></hr>
                                    <div className='order-total'>
                                        <div className='d-flex justify-content-between mb-3'>
                                            <span>Tax</span>
                                            <strong>$25</strong>
                                        </div>
                                        <div className='d-flex justify-content-between mb-3'>
                                            <span>Tax</span>
                                            <strong>$25</strong>
                                        </div>
                                        <div className='d-flex total-sum justify-content-between'>
                                            <span>Total</span>
                                            <strong>$8524</strong>
                                        </div>
                                        <form className='pt-5'>
                                            <div class="mb-3 mt-4">
                                                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Apply Coupon Code" />
                                            </div>
                                            <button type="button" class="btn btn-secondary w-100 search">Proceed To Payment <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.94766 6.00043L0.347656 1.40043L1.40141 0.34668L7.05516 6.00043L1.40141 11.6542L0.347656 10.6004L4.94766 6.00043Z" fill="white" />
                                            </svg></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Checkout
