import React from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import Layout from './Layout'
import { verifyOtpAction } from '../redux/actions/AuthAction'

const VerifyOtp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const emailFromQuery = searchParams.get('email');

  // Formik setup
  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: Yup.object({
      otp: Yup.string().required('OTP is required') // Adjust validation as needed
    }),
    onSubmit: async (values) => {
      try {
        let payload = {}
        if (emailFromQuery) {
          payload = {
            email: emailFromQuery,
            otp: values.otp
          }
        } else {
          payload = {
            otp: values.otp
          }
        }
        console.log("payoad", payload)
        const data = await dispatch(verifyOtpAction(payload))
        if (data?.payload?.data?._id && emailFromQuery) {
          toast.success(data?.payload?.message)
          navigate(`/setPassword?_id=${data?.payload?.data?._id}`)
        } else if (data.payload.message === "Wrong otp") {
          toast.error(data?.payload?.message)
        } else if (data) {
          navigate(`/`)
          sessionStorage.setItem('token', values.otp)
          toast.success("login successfully")
        }
      } catch (error) {
        toast.error('An error occurred while verifying OTP.')
      }
    }
  })

  return (
    <Layout>
      <section className="login-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="pt-content">
                <ul>
                  <li>Save searches and favorite plans.</li>
                  <li>Access your account from any device including favorites, cart, and order history.</li>
                  <li>Sign up for our newsletter for coupons and sales.</li>
                  <li>No password required.</li>
                </ul>
                <div className="text-end pb-4">
                  <img src="./images/house-bg.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login-form">
                <h2>Verify OTP</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      name="otp"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Type here"
                      value={formik.values.otp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.otp && formik.errors.otp ? (
                      <div className="text-danger">{formik.errors.otp}</div>
                    ) : null}
                  </div>

                  <button type="submit" className="btn btn-primary btn-login">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default VerifyOtp
