// components/DashboardLayout.js
import React, { useEffect } from "react";
import Layout from "./Layout";
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import houseicon from '../assets/images/house-icon.png';
import aspect_ratio from '../assets/images/aspect_ratio.svg'
import floor from '../assets/images/floor.svg'
import apartment from '../assets/images/apartment.svg'
import directions_car from '../assets/images/directions_car.svg'
import garage_door from '../assets/images/garage_door.png'
import garage_home from '../assets/images/garage_home.png'
import bathtub from '../assets/images/bathtub.png'
import countertops from '../assets/images/countertops.png'

import imghouse1 from "../assets/images/img1.png";
import exclusive from "../assets/images/exclusive.svg";
import youtubr from "../assets/images/youtubr.svg";
import fav from "../assets/images/fav.svg";
import dry_cleaning from "../assets/images/dry_cleaning.svg"
import king_bed from "../assets/images/king_bed.png"
import pool from "../assets/images/pool.png"
import foundation from "../assets/images/foundation.png"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { listPropertyAction } from "../redux/actions/PropertyAction";

const PropertyList = () => {
  const dispatch = useDispatch();
  const { propertyList } = useSelector((state) => state.property)
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listPropertyAction());
  }, []);

  console.log("propertyList", propertyList);

  return (
    <Layout>
      <section className='search'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='filters'>
                <div className='fil-title'>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.25 17.75V12.25H9.75V14.25H17.75V15.75H9.75V17.75H8.25ZM0.25 15.75V14.25H5.75V15.75H0.25ZM4.25 11.75V9.75H0.25V8.25H4.25V6.25H5.75V11.75H4.25ZM8.25 9.75V8.25H17.75V9.75H8.25ZM12.25 5.75V0.25H13.75V2.25H17.75V3.75H13.75V5.75H12.25ZM0.25 3.75V2.25H9.75V3.75H0.25Z" fill="#F46530" />
                  </svg>
                  <span>Filters</span>
                </div>
                <div className='typeflor'>
                  <h5>Type</h5>
                  <div className='d-flex'>
                    <div className='planimg'>
                      <button type="button" class="btn btn-secondary"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2077 17.7083V14.5417H5.29102C4.8556 14.5417 4.48286 14.3866 4.17279 14.0766C3.86272 13.7665 3.70768 13.3938 3.70768 12.9583V5.04167H0.541016V3.45833H3.70768V0.291667H5.29102V12.9583H17.9577V14.5417H14.791V17.7083H13.2077ZM13.2077 11.375V5.04167H6.87435V3.45833H13.2077C13.6431 3.45833 14.0158 3.61337 14.3259 3.92344C14.636 4.23351 14.791 4.60625 14.791 5.04167V11.375H13.2077Z" fill="white" />
                      </svg> Plan Images</button>
                    </div>
                    <div className='florpln'>
                      <button type="button" class="btn btn-outline-secondary"><svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_686_25882" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                          <rect x="0.25" y="9.53674e-07" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_686_25882)">
                          <path d="M3 20.1667V18.3333H6.20833V14.2083H10.3333V10.0833H14.4583V5.95833H18.5833V2.75H20.4167V7.79167H16.2917V11.9167H12.1667V16.0417H8.04167V20.1667H3Z" fill="#666666" />
                        </g>
                      </svg> Floor Plans</button>
                    </div>
                  </div>
                </div>
                <div className='keyword'>
                  <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Enter a keyword</Form.Label>
                      <div className='d-flex'>
                        <Form.Control type="email" placeholder="Keeping  room" />
                        <button type="button" class="btn btn-secondary ms-2">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.41691 15.4879L6.90011 10.0045C7.33661 10.3649 7.8386 10.6469 8.40606 10.8506C8.97352 11.0543 9.56055 11.1562 10.1672 11.1562C11.6593 11.1562 12.9221 10.6396 13.9556 9.60637C14.9891 8.57315 15.5059 7.31063 15.5059 5.81879C15.5059 4.32709 14.9893 3.06413 13.956 2.0299C12.9228 0.995811 11.6603 0.478767 10.1685 0.478767C8.67677 0.478767 7.4138 0.995519 6.37957 2.02902C5.34548 3.06253 4.82844 4.32535 4.82844 5.81748C4.82844 6.44096 4.93306 7.03643 5.14229 7.60389C5.35167 8.17135 5.63096 8.66489 5.98017 9.08452L0.496971 14.5677L1.41691 15.4879ZM10.1672 9.84688C9.04227 9.84688 8.08952 9.4565 7.3089 8.67573C6.52813 7.89511 6.13775 6.94236 6.13775 5.81748C6.13775 4.6926 6.52813 3.73984 7.3089 2.95922C8.08952 2.17846 9.04227 1.78807 10.1672 1.78807C11.292 1.78807 12.2448 2.17846 13.0254 2.95922C13.8062 3.73984 14.1966 4.6926 14.1966 5.81748C14.1966 6.94236 13.8062 7.89511 13.0254 8.67573C12.2448 9.4565 11.292 9.84688 10.1672 9.84688Z" fill="white" />
                          </svg>

                        </button>
                      </div>
                    </Form.Group>

                  </Form>
                </div>
                <div className='keyword'>
                  <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Total Heated Area (Sq Ft.)</Form.Label>
                      <div className='d-flex total-area'>
                        <Form.Control type="text" placeholder="00" />
                        <Form.Control type="text" placeholder="00" />
                        <button type="button" class="btn btn-secondary ms-2">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.41691 15.4879L6.90011 10.0045C7.33661 10.3649 7.8386 10.6469 8.40606 10.8506C8.97352 11.0543 9.56055 11.1562 10.1672 11.1562C11.6593 11.1562 12.9221 10.6396 13.9556 9.60637C14.9891 8.57315 15.5059 7.31063 15.5059 5.81879C15.5059 4.32709 14.9893 3.06413 13.956 2.0299C12.9228 0.995811 11.6603 0.478767 10.1685 0.478767C8.67677 0.478767 7.4138 0.995519 6.37957 2.02902C5.34548 3.06253 4.82844 4.32535 4.82844 5.81748C4.82844 6.44096 4.93306 7.03643 5.14229 7.60389C5.35167 8.17135 5.63096 8.66489 5.98017 9.08452L0.496971 14.5677L1.41691 15.4879ZM10.1672 9.84688C9.04227 9.84688 8.08952 9.4565 7.3089 8.67573C6.52813 7.89511 6.13775 6.94236 6.13775 5.81748C6.13775 4.6926 6.52813 3.73984 7.3089 2.95922C8.08952 2.17846 9.04227 1.78807 10.1672 1.78807C11.292 1.78807 12.2448 2.17846 13.0254 2.95922C13.8062 3.73984 14.1966 4.6926 14.1966 5.81748C14.1966 6.94236 13.8062 7.89511 13.0254 8.67573C12.2448 9.4565 11.292 9.84688 10.1672 9.84688Z" fill="white" />
                          </svg>

                        </button>
                      </div>
                    </Form.Group>

                  </Form>
                </div>
                <Form.Label>Category</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
                <Form.Label>Bathrooms</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
                <hr></hr>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header><img src={houseicon} />Plan Type</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header><img src={aspect_ratio} />Footprint</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header><img src={floor} />Floor</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header><img src={apartment} />Styles</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header><img src={directions_car} />Number of Vehicles</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header><img src={garage_door} />Garage Type</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header><img src={garage_home} />Garage Location</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header><img src={bathtub} />Bedroom & Bath Options</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header><img src={countertops} />Kitchen and Dining</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header><img src={dry_cleaning} />Kitchen and Dining</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header><img src={king_bed} />Kitchen and Dining</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header><img src={pool} />Kitchen and Dining</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header><img src={foundation} />Kitchen and Dining</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Stand-Alone Garages  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Other (sheds, pool houses, offices) `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Single-Family Homes  `}
                                />
                                <span className='all-tot'>(1,779)</span>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='farmplans'>
                <span>1,854 plans found!</span>
                <h4>Modern Farmhouse Plans</h4>
              </div>
              <div className='plans-farms'>
                {
                  Array.isArray(propertyList) && propertyList?.length > 0 ?
                    propertyList?.map((res, index) => {
                      return <>
                        <div className="wrap" key={res?._id}>
                          <Link to={`/property/${res?._id}`} className="custom-link">
                            <div className="img-h">
                              <img src={res?.images?.length > 0 ? res?.images[0] : imghouse1} style={{ height: '250px', width: '280px' }} />
                              <div className="sociali-btn">
                                <div className="youtube">
                                  <img src={youtubr} />
                                </div>
                                <div className="fav">
                                  <img src={fav} />
                                </div>
                              </div>
                            </div>
                            <div className="ex-tag">
                              <img src={exclusive} />
                            </div>
                            <div className="img-content">
                              <div className="h-details">
                                <div className="area">
                                  <strong>{res?.sq_ft || '0'}</strong>
                                  <p className="mb-0">Sq. Ft.</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.beds || '0'}</strong>
                                  <p className="mb-0">Bed</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.baths || '0'}</strong>
                                  <p className="mb-0">Bath</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.story || '0'}</strong>
                                  <p className="mb-0">Story</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.cars || '0'}</strong>
                                  <p className="mb-0">Cars</p>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="d-flex justify-content-between">
                                <div className="pln-no">
                                  <span>Plan Number</span>
                                  <h2>{res?.plan_number ? `#${res?.plan_number}` : 'N/A'}</h2>
                                </div>
                                <div className="pln-no">
                                  <span>Starts From</span>
                                  <h2 className="price">${res?.price || '0'}</h2>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    })
                    : <p className="text-center">No Data Found</p>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PropertyList;
