import React from 'react'
import Layout from './Layout'
import imghouse1 from "../assets/images/img1.png";
import fav from "../assets/images/fav.svg";

const Testimonial = () => {
    return (
        <>
            <Layout>
                <div className="top-heading mt-5">
                    <h2>Testimonials</h2>
                </div>
                <section className='testimonial'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto mt-4'>
                            <div className='review-wrap'>
                                <div className="wrap">
                                    <div className="img-h">
                                        <img src={imghouse1} />
                                        <div className="sociali-btn">

                                            <div className="fav">
                                                <img src={fav} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="img-content">

                                        <div className="d-flex justify-content-between">
                                            <div className="pln-no">
                                                <span>Plan Number</span>
                                                <h2>#714035BTZ</h2>
                                            </div>
                                            <div className="pln-no">
                                                <span>Starts From</span>
                                                <h2 className="price">$2,095</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='content-review'>
                                    <p>“Y’all have been so easy to work with and I really appreciate the quick responses! I will definitely be sending all my clients to ADesign for house plans.”</p>
                                    <strong>Michael from TX</strong>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-10 mx-auto mt-4'>
                            <div className='review-wrap'>
                                <div className="wrap">
                                    <div className="img-h">
                                        <img src={imghouse1} />
                                        <div className="sociali-btn">

                                            <div className="fav">
                                                <img src={fav} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="img-content">

                                        <div className="d-flex justify-content-between">
                                            <div className="pln-no">
                                                <span>Plan Number</span>
                                                <h2>#714035BTZ</h2>
                                            </div>
                                            <div className="pln-no">
                                                <span>Starts From</span>
                                                <h2 className="price">$2,095</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='content-review'>
                                    <p>“Y’all have been so easy to work with and I really appreciate the quick responses! I will definitely be sending all my clients to ADesign for house plans.”</p>
                                    <strong>Michael from TX</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Testimonial
