import React from 'react'
import Layout from './Layout'
import emailcon from '../assets/images/email-con.svg'
import phonenosvg from '../assets/images/phonenosvg.svg'
import fax from '../assets/images/fax.svg'

const ContactUs = () => {
  return (
    <Layout>
      <section className='contact'>
        <div className='top-heading'>
          <h2>Contact Us</h2>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='contact-details'>
                <h3>Questions on a plan? We’re here to help. Fill out the form below and we’ll get you answers.</h3>
                <div className='email'>
                  <img src={emailcon} />
                  <div className='ms-3'>
                    <span>Email:</span>
                    <p>hello@hoadesk.com</p>
                  </div>
                </div>
                <div className='email'>
                  <img src={phonenosvg} />
                  <div className='ms-3'>
                    <span>Call Center available 24/7</span>
                    <p>75021000555</p>
                  </div>
                </div>
                <div className='email'>
                  <img src={phonenosvg} />
                  <div className='ms-3'>
                    <span>International Phone</span>
                    <p>75021000555</p>
                  </div>
                </div>
                <div className='email'>
                  <img src={fax} />
                  <div className='ms-3'>
                    <span>Fax</span>
                    <p>75021000555</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='contact-form'>
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" placeholder="Requester Name" />
                  <label for="floatingInput">Requester Name</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
                  <label for="floatingInput">Email address</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" placeholder="Subject" />
                  <label for="floatingInput">Subject</label>
                </div>
                <div class="form-floating">
                  <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                  <label for="floatingTextarea">Comments</label>
                </div>
                <button type="button" class="btn btn-secondary search">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactUs
