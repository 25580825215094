import React from 'react';
import Layout from './Layout';
import houseImg from '../assets/images/house-bg.png';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setPasswordAction } from '../redux/actions/AuthAction';

const SetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const _idFromQuery = searchParams.get('_id');

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
        let payload = {
            _id:_idFromQuery,
            password: values.newPassword
        }
      const data = await dispatch(setPasswordAction(payload));
      if (data?.payload?.message == 'Password reset successfully, Please login to continue') {
        toast.success(data?.payload?.message);
        navigate('/SignIn');
      } else {
        toast.error(data?.payload?.message);
      }
    },
  });

  return (
    <Layout>
      <section className="login-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="pt-content">
                <ul>
                  <li>Save searches and favorite plans.</li>
                  <li>Access your account from any device including favorites, cart, and order history.</li>
                  <li>Sign up for our newsletter for coupons and sales.</li>
                  <li>No password required.</li>
                </ul>
                <div className="text-end pb-4">
                  <img src={houseImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login-form">
                <h2>Set New Password</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control"
                      id="newPassword"
                      placeholder="New Password"
                      name="newPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                    />
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                      <div className="error">{formik.errors.newPassword}</div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      placeholder="Confirm New Password"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                      <div className="error">{formik.errors.confirmPassword}</div>
                    ) : null}
                  </div>
                  <button type="submit" className="btn btn-primary btn-login">Submit</button>
                </form>
                <div className="lost-psd text-center">
                  <Link to={`/SignInEmail`}>Click here to skip the password and sign in with just your email and a link</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SetPassword;
