import React from 'react'
import Layout from './Layout'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { loginAction } from '../redux/actions/AuthAction';
import { Form } from 'react-bootstrap';

const SignIn = () => {
  const dispatch = useDispatch();

  const validateSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required"),
    password: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      let data = await dispatch(loginAction(values));
      if (data?.payload?.status === 200) {
        sessionStorage.setItem('token', data?.payload?.token)
        toast.success(data?.payload?.message)
        window.location.href = '/'
      } else {
        toast.error(data.payload.message)
      }
    }
  })

  return (
    <Layout>
      <section className="login-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="pt-content">
                <ul>
                  <li>Save searches and favorite plans.</li>
                  <li>Access your account from any device including favorites, cart, and order history.</li>
                  <li>Sign up for our newsletter for coupons and sales.</li>
                  <li>No password required.</li>
                </ul>
                <div className="text-end pb-4">
                  <img src="./images/house-bg.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login-form">
                <Form onSubmit={formik.handleSubmit}>
                  <h2>Login or Register to Enjoy These Great Benefits</h2>
                  <div className="mb-3">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Type Here"
                        name="email"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.submitCount > 0 && formik.errors.email && (
                        <div className="error">{formik.errors.email}</div>
                      )}
                    </Form.Group>

                    {/* <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" /> */}
                  </div>
                  <div className="mb-3">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Type Here"
                        name="password"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.submitCount > 0 && formik.errors.password && (
                        <div className="error">{formik.errors.password}</div>
                      )}
                    </Form.Group>

                  </div>
                  <div className="lost-psd text-center mb-4">
                    <Link to={`/resetPass`}>Forgot Password</Link>
                  </div>
                  <button
                    //  to="/Otp"
                    variant="primary"
                    type="submit"
                    className="btn btn-primary btn-login"
                  >
                    Log In
                  </button>
                  <div className="lost-psd text-center">
                    <Link to={`/SignInEmail`}>Click here to skip the password and sign in with just your email and a link</Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SignIn