import React from 'react'
import Layout from './Layout'
import imghouse1 from "../assets/images/img1.png";
import youtubr from "../assets/images/youtubr.svg";
import fav from "../assets/images/fav.svg";
import exclusive from "../assets/images/exclusive.svg";

const FavoriteDetails = () => {
    return (
        <>
            <Layout>
                <section className='favourite '>
                    <div className='container'>
                        <div className='text-center top-tiles'>
                            <h2>My Favorites <span className='count'>(48)</span></h2>

                        </div>
                        <div className='row'>
                            <div className='col-lg-4 d-flex justify-content-center'>
                                <div className="wrap">
                                    <div className="img-h">
                                        <img src={imghouse1} />
                                        <div className="sociali-btn">
                                            <div className="youtube">
                                                <img src={youtubr} />
                                            </div>
                                            <div className="fav">
                                                <img src={fav} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ex-tag">
                                        <img src={exclusive} />
                                    </div>
                                    <div className="img-content">
                                        <div className="h-details">
                                            <div className="area">
                                                <strong>2369</strong>
                                                <p className="mb-0">Sq. Ft.</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>3</strong>
                                                <p className="mb-0">Bed</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>3</strong>
                                                <p className="mb-0">Bath</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>1</strong>
                                                <p className="mb-0">Story</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>2</strong>
                                                <p className="mb-0">Cars</p>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="d-flex justify-content-between">
                                            <div className="pln-no">
                                                <span>Plan Number</span>
                                                <h2>#714035BTZ</h2>
                                            </div>
                                            <div className="pln-no">
                                                <span>Starts From</span>
                                                <h2 className="price">$2,095</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 d-flex justify-content-center'>
                                <div className="wrap">
                                    <div className="img-h">
                                        <img src={imghouse1} />
                                        <div className="sociali-btn">
                                            <div className="youtube">
                                                <img src={youtubr} />
                                            </div>
                                            <div className="fav">
                                                <img src={fav} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ex-tag">
                                        <img src={exclusive} />
                                    </div>
                                    <div className="img-content">
                                        <div className="h-details">
                                            <div className="area">
                                                <strong>2369</strong>
                                                <p className="mb-0">Sq. Ft.</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>3</strong>
                                                <p className="mb-0">Bed</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>3</strong>
                                                <p className="mb-0">Bath</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>1</strong>
                                                <p className="mb-0">Story</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>2</strong>
                                                <p className="mb-0">Cars</p>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="d-flex justify-content-between">
                                            <div className="pln-no">
                                                <span>Plan Number</span>
                                                <h2>#714035BTZ</h2>
                                            </div>
                                            <div className="pln-no">
                                                <span>Starts From</span>
                                                <h2 className="price">$2,095</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 d-flex justify-content-center'>
                                <div className="wrap">
                                    <div className="img-h">
                                        <img src={imghouse1} />
                                        <div className="sociali-btn">
                                            <div className="youtube">
                                                <img src={youtubr} />
                                            </div>
                                            <div className="fav">
                                                <img src={fav} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ex-tag">
                                        <img src={exclusive} />
                                    </div>
                                    <div className="img-content">
                                        <div className="h-details">
                                            <div className="area">
                                                <strong>2369</strong>
                                                <p className="mb-0">Sq. Ft.</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>3</strong>
                                                <p className="mb-0">Bed</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>3</strong>
                                                <p className="mb-0">Bath</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>1</strong>
                                                <p className="mb-0">Story</p>
                                            </div>
                                            <hr></hr>
                                            <div className="area">
                                                <strong>2</strong>
                                                <p className="mb-0">Cars</p>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="d-flex justify-content-between">
                                            <div className="pln-no">
                                                <span>Plan Number</span>
                                                <h2>#714035BTZ</h2>
                                            </div>
                                            <div className="pln-no">
                                                <span>Starts From</span>
                                                <h2 className="price">$2,095</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </Layout>
        </>
    )
}

export default FavoriteDetails
