import React from 'react'
import Layout from './Layout'

const Services = () => {
  return (
   <>
   <Layout>
    <div className='m-5'>
      <h2>Comming Soon...</h2>
    </div>
   </Layout>
   </>
  )
}

export default Services
