import React from 'react'
import Layout from './Layout'
import rating from "../assets/images/rating.png"
import gogle from "../assets/images/gogle.png"

const Reviews = () => {
    return (
        <>
            <Layout>
                <div className='container'>
                    <div className="top-heading mt-5">
                        <h2>Reviews</h2>
                    </div>
                </div>
                <section className='review'>
                    <div className='container'>
                        <div className='review-cover'>
                            <div className='rev-wrap'>
                                <h4>User Name</h4>
                                <p>Role</p>
                                <div className='rating'>
                                    <img src={rating} />
                                </div>
                                <p>Malesuada facilisi libero, nam eu. Quis pellentesque tortor a elementum ut blandit sed pellentesque arcu. Malesuada in faucibus risus velit diam. Non, massa ut a arcu, fermentum, vel interdum.</p>
                                <img src={gogle} />
                            </div>
                            <div className='rev-wrap'>
                                <h4>User Name</h4>
                                <p>Role</p>
                                <div className='rating'>
                                    <img src={rating} />
                                </div>
                                <p>Malesuada facilisi libero, nam eu. Quis pellentesque tortor a elementum ut blandit sed pellentesque arcu. Malesuada in faucibus risus velit diam. Non, massa ut a arcu, fermentum, vel interdum.</p>
                                <img src={gogle} />
                            </div>
                            <div className='rev-wrap'>
                                <h4>User Name</h4>
                                <p>Role</p>
                                <div className='rating'>
                                    <img src={rating} />
                                </div>
                                <p>Malesuada facilisi libero, nam eu. Quis pellentesque tortor a elementum ut blandit sed pellentesque arcu. Malesuada in faucibus risus velit diam. Non, massa ut a arcu, fermentum, vel interdum.</p>
                                <img src={gogle} />
                            </div>
                            <div className='rev-wrap'>
                                <h4>User Name</h4>
                                <p>Role</p>
                                <div className='rating'>
                                    <img src={rating} />
                                </div>
                                <p>Malesuada facilisi libero, nam eu. Quis pellentesque tortor a elementum ut blandit sed pellentesque arcu. Malesuada in faucibus risus velit diam. Non, massa ut a arcu, fermentum, vel interdum.</p>
                                <img src={gogle} />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Reviews
