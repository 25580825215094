import React from 'react'
import Layout from './Layout'
import btnarrow from "../assets/images/btn-arrow.svg";
import btmsvg from "../assets/images/btm-svg.svg"

const Pricing = () => {
    return (
        <>
            <Layout>
                <div className="top-heading mt-5">

                    <h2>Pricing</h2>
                </div>
                <section className="ourprize my-5">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="left-ban-text">
                                    <h4>Our Prices are gurantee</h4>
                                    <p>If you find a better price elsewhere, we will match it and give you an additional 10% off the matched price*. That's our guarantee! Email us or call us to take advantage of this offer.</p>
                                    <div className="cont-details">
                                        <div>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <mask id="mask0_29_2732" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                                                    <rect width="22" height="22" fill="#D9D9D9" />
                                                </mask>
                                                <g mask="url(#mask0_29_2732)">
                                                    <path d="M17.8199 18.7918C16.0924 18.7918 14.357 18.3902 12.6135 17.5869C10.8701 16.7836 9.26687 15.6504 7.80372 14.1874C6.34652 12.7243 5.21627 11.1224 4.41297 9.38179C3.60966 7.64134 3.20801 5.90739 3.20801 4.17993C3.20801 3.90493 3.29967 3.67424 3.48301 3.48785C3.66634 3.30161 3.89551 3.2085 4.17051 3.2085H7.16022C7.39167 3.2085 7.59586 3.28405 7.77278 3.43514C7.94969 3.58609 8.06222 3.77263 8.11034 3.99477L8.63582 6.69183C8.67218 6.94208 8.66454 7.15711 8.6129 7.33693C8.56111 7.51675 8.46822 7.66777 8.33424 7.79L6.21719 9.85089C6.55789 10.4748 6.94717 11.0651 7.38503 11.6217C7.82274 12.1781 8.29665 12.7095 8.80678 13.216C9.30972 13.7191 9.84444 14.1863 10.4109 14.6176C10.9774 15.0488 11.5892 15.4502 12.2461 15.8216L14.3031 13.7467C14.4466 13.5975 14.6203 13.4928 14.8242 13.4328C15.028 13.3729 15.2399 13.3582 15.4597 13.3888L18.0051 13.9071C18.2365 13.9683 18.4254 14.0863 18.5718 14.2614C18.7182 14.4365 18.7913 14.6351 18.7913 14.8573V17.8293C18.7913 18.1043 18.6982 18.3335 18.512 18.5168C18.3256 18.7002 18.0949 18.7918 17.8199 18.7918Z" fill="black" />
                                                </g>
                                            </svg>
                                            <span>+1 (999) 999-99-99</span>
                                        </div>
                                        <div>
                                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <mask id="mask0_29_2736" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="22">
                                                    <rect x="0.5" width="22" height="22" fill="#D9D9D9" />
                                                </mask>
                                                <g mask="url(#mask0_29_2736)">
                                                    <path d="M4.4491 17.875C3.98603 17.875 3.59408 17.7146 3.27324 17.3937C2.95241 17.0729 2.79199 16.681 2.79199 16.2179V5.7821C2.79199 5.31903 2.95241 4.92708 3.27324 4.60625C3.59408 4.28542 3.98603 4.125 4.4491 4.125H18.5516C19.0146 4.125 19.4066 4.28542 19.7274 4.60625C20.0482 4.92708 20.2087 5.31903 20.2087 5.7821V16.2179C20.2087 16.681 20.0482 17.0729 19.7274 17.3937C19.4066 17.7146 19.0146 17.875 18.5516 17.875H4.4491ZM11.5003 11.5113L18.8337 6.82206L18.6927 5.5L11.5003 10.0833L4.30793 5.5L4.16699 6.82206L11.5003 11.5113Z" fill="black" />
                                                </g>
                                            </svg>
                                            <span>hello@logoipsum.com</span>
                                        </div>

                                    </div>
                                    <div className="btm-butn">
                                        <button type="button" class="btn btn-primary">
                                            Contact Us
                                            <img src={btnarrow} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <img src={btmsvg} />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Pricing
