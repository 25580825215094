import { configureStore } from '@reduxjs/toolkit';
import authSlice from './reducers/authSlice';
import propertySlice from './reducers/propertySLice'; 

const store = configureStore({
    reducer: {
        authData: authSlice,
        property: propertySlice
    }
});

export default store;