import "./App.css";
import './assets/css/style.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./pages/ResetPassword";
import VerifyOtp from "./pages/VerifyOtp";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import SignInEmail from "./pages/SignInEmail";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SetPassword from "./pages/SetPassword";
import PropertyList from "./pages/PropertyList";
import Styles from "./pages/Styles";
import CostToBuild from "./pages/CostToBuild";
import MultiFamily from "./pages/MultiFamily";
import Services from "./pages/Services";
import Garages from "./pages/Garages";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PropertyDetail from "./pages/PropertyDetail";
import "pure-react-carousel/dist/react-carousel.es.css";
import CopyRight from "./pages/CopyRight";
import Favorite from "./pages/Favorite";
import FavoriteDetails from "./pages/FavoriteDetails";
import FAQ from "./pages/FAQ";
import Usersettings from "./pages/Usersettings";
import Checkout from "./pages/Checkout";
import Testimonial from "./pages/Testimonial";
import Reviews from "./pages/Reviews";
import Pricing from "./pages/Pricing";
import Blog from "./pages/Blog";
import Cart from "./pages/Cart";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/SignIn" element={<SignIn />}></Route>
            <Route path="/SignInEmail" element={<SignInEmail />}></Route>
            <Route path="/verifyOtp" element={<VerifyOtp />}></Route>
            <Route path="/resetPass" element={<ResetPassword />} />
            <Route path="/setPassword" element={<SetPassword />} />
            <Route path="/" element={<Home />}></Route>
            <Route path="/propertyList" element={<PropertyList />}></Route>
            <Route path="/property/:id" element={<PropertyDetail />}></Route>
            <Route path="/styles" element={<Styles />}></Route>
            <Route path="/cost-to-build" element={<CostToBuild />}></Route>
            <Route path="/multi-family" element={<MultiFamily />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/garages" element={<Garages />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/copyright" element={<CopyRight />}></Route>
            <Route path="/faq" element={<FAQ />} ></Route>
            <Route path="/usersettings" element={<Usersettings />} ></Route>
            <Route path="/favorite" element={<Favorite />} ></Route>
            <Route path="/favoritedetails" element={<FavoriteDetails />} ></Route>
            <Route path="/checkout" element={<Checkout />} ></Route>
            <Route path="/pricing" element={<Pricing />} ></Route>
            <Route path="/testimonial" element={<Testimonial />} ></Route>
            <Route path="/reviews" element={<Reviews />} ></Route>
            <Route path="/blog" element={<Blog />} ></Route>
            <Route path="/cart" element={<Cart />} ></Route>
          </Route>

          <Route element={<PrivateRoute />}>
            {/* <Route index element={<DashboardLayout />} /> */}
          </Route>
        </Routes>
        <ToastContainer limit={1} />
      </BrowserRouter>
    </div>
  );
}

export default App;