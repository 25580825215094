import React from 'react'
import Layout from './Layout'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Usersettings = () => {
    return (
        <>
            <Layout>
                <section className='usersettings'>
                    <div className='container'>
                        <h2>User Settings</h2>
                        <div className='form'>
                            <div className='mb-3'>
                                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                            </div>
                            <div className='mb-3'>
                                <input type="email" class="form-control" id="exampleFormControlInput2" placeholder="Email" />
                            </div>
                            <div className='email-subscription'>
                                <div className='d-flex justify-content-between'>
                                    <h3>Email Subscription</h3>
                                    <span>Unsubscribe from all</span>
                                </div>
                                <Form>
                                    {['checkbox'].map((type) => (
                                        <div key={`default-${type}`} className="mb-3">
                                            <Form.Check // prettier-ignore
                                                type={type}
                                                id={`default-${type}`}
                                                label={`Favorite plan updates: We'll update you no more than once a day with a digest of any changes to house plans you've favorited. ${type}`}
                                            />

                                            <Form.Check

                                                type={type}
                                                label={`Favorite plan updates: We'll update you no more than once a day with a digest of any changes to house plans you've favorited. ${type}`}
                                                id={`disabled-default-${type}`}
                                            />
                                            <Form.Check

                                                type={type}
                                                label={`Favorite plan updates: We'll update you no more than once a day with a digest of any changes to house plans you've favorited. ${type}`}
                                                id={`disabled-default-${type}`}
                                            />
                                        </div>
                                    ))}
                                </Form>
                            </div>
                            <div className='reward email-subscription'>
                                <div className='d-flex justify-content-between'>
                                    <h3>Reward Program </h3>
                                    <span>Unsubscribe from all</span>
                                </div>
                                <Form>
                                    {['checkbox'].map((type) => (
                                        <div key={`default-${type}`} className="mb-3">
                                            <Form.Check // prettier-ignore
                                                type={type}
                                                id={`default-${type}`}
                                                label={`I am a professional builder ${type}`}
                                            />
                                        </div>
                                    ))}
                                </Form>
                                <div className='mb-3'>
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="First Name" />
                                </div>
                                <div className='mb-3'>
                                    <input type="email" class="form-control" id="exampleFormControlInput2" placeholder="Email" />
                                </div>
                                <Form>
                                    {['checkbox'].map((type) => (
                                        <div key={`default-${type}`} className="mb-3">
                                            <Form.Check // prettier-ignore
                                                type={type}
                                                id={`default-${type}`}
                                                label={`I am a real estate agent ${type}`}
                                            />
                                            <Form.Check // prettier-ignore
                                                type={type}
                                                id={`default-${type}`}
                                                label={`I am neither ${type}`}
                                            />
                                        </div>
                                    ))}
                                </Form>
                            </div>
                            <div className='affiliate email-subscription'>
                                <div className='d-flex'>
                                    <h3>Affiliate</h3>

                                </div>
                                <div className='mb-3'>
                                    <input type="text" class="form-control" id="exampleFormControlInput4" placeholder="CJ.com PID (Publisher Website ID)" />
                                </div>
                            </div>
                            <Button variant="secondary" className='search'>Submit</Button>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Usersettings
