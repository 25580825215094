import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Layout from './Layout';
import googleImg from "../assets/images/google.png";
import facebookImg from "../assets/images/Icon_11_.png";
import amazonImg from "../assets/images/image_149.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify'; 
import { loginAction } from '../redux/actions/AuthAction';

const SignInEmail = () => {
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const data = await dispatch(loginAction(values));
            if(data?.payload?.status === 200){
                toast.success(`${data?.payload?.message} and your Otp is ${data?.payload?.otp}`)
                navigate(`/verifyOtp?otp=${data?.payload?.otp}`)
            } else {
                toast.error(data?.payload?.message)
            }
        } catch (error) {
            console.error("Submission error:", error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Layout>
            <section className="login-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="pt-content">
                                <ul>
                                    <li>Save searches and favorite plans.</li>
                                    <li>Access your account from any device including favorites, cart, and order history.</li>
                                    <li>Sign up for our newsletter for coupons and sales.</li>
                                    <li>No password required.</li>
                                </ul>
                                <div className="text-end pb-4">
                                    <img src="./images/house-bg.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-form">
                                <h2>Login or Register to Enjoy These Great Benefits</h2>
                                <Formik
                                    initialValues={{ email: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Email Address"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <p>By registering you are accepting our <a href="#">terms and conditions</a> and <a href="#">privacy policy</a></p>
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-login"
                                                disabled={isSubmitting}
                                            >
                                                Continue With Email
                                            </button>
                                            <div className="signin">
                                                <hr /><span>Or Sign with</span><hr />
                                            </div>
                                            <div className="social-media-btns">
                                                <button type="button" className="btn btn-light">
                                                    <img src={googleImg} alt="" /> Continue with Google
                                                </button>
                                                <button type="button" className="btn btn-light">
                                                    <img src={facebookImg} alt="" /> Continue with Facebook
                                                </button>
                                                <button type="button" className="btn btn-light">
                                                    <img src={amazonImg} alt="" /> Continue with Amazon
                                                </button>
                                            </div>
                                            <div className="lost-psd text-center">
                                                <Link to={`/SignIn`} >Login with Password</Link>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default SignInEmail;
