// components/DashboardLayout.js
import React from "react";
import Layout from "./Layout";
import { Button } from "react-bootstrap";
import housebg from "../assets/images/house-bg.png";
import history from "../assets/images/history.svg";
import cost from "../assets/images/cost.svg";
import lowest from "../assets/images/lowest.svg";
import porticon from "../assets/images/port-icon.svg";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import imghouse1 from "../assets/images/img1.png";
import exclusive from "../assets/images/exclusive.svg";
import youtubr from "../assets/images/youtubr.svg";
import fav from "../assets/images/fav.svg";
import rightarrow from "../assets/images/right-arrow.svg";
import leftarrow from "../assets/images/left-arrow.svg";
import bgimg from "../assets/images/bg-img.png";
import imggal1 from "../assets/images/img-gal1.png";
import imggal2 from "../assets/images/img-gal2.png";
import imggal3 from "../assets/images/img-gal3.png";
import imggal4 from "../assets/images/imggal4.png";
import imggal5 from "../assets/images/imggal5.png";
import imggal6 from "../assets/images/imggal6.png";
import imggal7 from "../assets/images/imggal7.png";
import PenNib from "../assets/images/PenNib.png";
import btnarrow from "../assets/images/btn-arrow.svg";
import btmsvg from "../assets/images/btm-svg.svg"

const Home = () => {
  const handleLogOut = () => {
    sessionStorage.clear();
    window.location.href = "/"
  }
  return (
    <Layout>
      {/* <div className="text-center">
        <h1 className="mt-5 mb-4">Dashboard</h1>
        <Button type="button" onClick={handleLogOut} variant="danger" className="mb-5"> Log Out</Button>
      </div> */}
      <section className="herobanner">
        <div className="container">
          <h2>Your Dream Home Awaits</h2>
          <div className="banner-search">
            <div className="bed">
              <select class="form-select" aria-label="Default select example">
                <option selected>Select Bed</option>
                <option value="1">Bed 1</option>
                <option value="2">Bed 2</option>
                <option value="3">Bed 3</option>
              </select>
            </div>
            <div className="bed">
              <select class="form-select" aria-label="Default select example">
                <option selected>Select Bath</option>
                <option value="1">Bed 1</option>
                <option value="2">Bed 2</option>
                <option value="3">Bed 3</option>
              </select>
            </div>
            <div className="bed">
              <select class="form-select" aria-label="Default select example">
                <option selected>Select Sq ft</option>
                <option value="1">Bed 1</option>
                <option value="2">Bed 2</option>
                <option value="3">Bed 3</option>
              </select>
            </div>
            <div className="bed">
              <select class="form-select" aria-label="Default select example">
                <option selected>Select Floors</option>
                <option value="1">Bed 1</option>
                <option value="2">Bed 2</option>
                <option value="3">Bed 3</option>
              </select>
            </div>

            <button type="button" className="btn btn-secondary search">
              {" "}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9145 15.8621L9.15714 10.1045C8.6988 10.4829 8.17172 10.7791 7.57588 10.993C6.98005 11.2069 6.36367 11.3138 5.72674 11.3138C4.16 11.3138 2.83404 10.7714 1.74886 9.68652C0.663684 8.60164 0.121094 7.27599 0.121094 5.70956C0.121094 4.14328 0.663531 2.81717 1.74841 1.73123C2.83328 0.645435 4.15893 0.102539 5.72536 0.102539C7.29164 0.102539 8.61775 0.645129 9.7037 1.73031C10.7895 2.81549 11.3324 4.14145 11.3324 5.70818C11.3324 6.36284 11.2225 6.98808 11.0028 7.58391C10.783 8.17975 10.4897 8.69797 10.1231 9.13858L15.8804 14.8959L14.9145 15.8621ZM5.72674 9.93906C6.90786 9.93906 7.90825 9.52916 8.72791 8.70935C9.54771 7.8897 9.95761 6.88931 9.95761 5.70818C9.95761 4.52706 9.54771 3.52667 8.72791 2.70702C7.90825 1.88721 6.90786 1.47731 5.72674 1.47731C4.54561 1.47731 3.54523 1.88721 2.72557 2.70702C1.90577 3.52667 1.49586 4.52706 1.49586 5.70818C1.49586 6.88931 1.90577 7.8897 2.72557 8.70935C3.54523 9.52916 4.54561 9.93906 5.72674 9.93906Z"
                  fill="white"
                />
              </svg>
              Search
            </button>
          </div>
        </div>
      </section>
      <section className="feature">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="pt-content">
                <h3>
                  Why Buy Plans From <br></br>Rholzsquad Architecture
                </h3>

                <p>
                  Find your dream place to live in with more than 10k+
                  properties listed.
                </p>
                <button type="button" class="btn btn btn-secondary ">
                  Browse Properties
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_489_8382"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="22"
                      height="22"
                    >
                      <rect width="22" height="22" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_489_8382)">
                      <path
                        d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </button>
                <div className="text-end pb-4">
                  <img src={housebg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="features-wrap">
                <div className="history">
                  <img src={history} />
                  <h5>40+ Years History</h5>
                  <p>
                    We offer our customer property protection of liability
                    coverage and insurance for their better life.
                  </p>
                </div>
                <div className="history">
                  <img src={cost} />
                  <h5>Mods & Cost to Build</h5>
                  <p>
                    Not sure what you should be charging for your property? No
                    need to worry, let us do the numbers for you.
                  </p>
                </div>
                <div className="history">
                  <img src={lowest} />
                  <h5>Lowest Commission</h5>
                  <p>
                    You no longer have to negotiate commissions and haggle with
                    other agents it only cost 2%!
                  </p>
                </div>
                <div className="history">
                  <img src={porticon} />
                  <h5>Curated Portfolio</h5>
                  <p>
                    Get a virtual tour, and schedule visits before you rent or
                    buy any properties. You get overall control.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="new-plans">
        <div className="container">
          <div className="top-heading">
            <p className="mb-0">New Plans</p>
            <h4>Explore our new added Houses</h4>
          </div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={40}
            totalSlides={3}
          >
            <Slider>
              <Slide index={0}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={1}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={2}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </Slider>
            <ButtonBack>
              <img src={leftarrow} />
            </ButtonBack>
            <ButtonNext>
              <img src={rightarrow} />
            </ButtonNext>
          </CarouselProvider>
          <div className="all-location">
            <button type="button" class="btn btn-outline-primary phno">
              {" "}
              View All Collections{" "}
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_504_23861"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="22"
                  height="22"
                >
                  <rect width="22" height="22" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_504_23861)">
                  <path
                    d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                    fill="#F46530"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section className="new-plans bg-white">
        <div className="container">
          <div className="top-heading">
            <p className="mb-0">Trending Plans</p>
            <h4>Explore our new added Houses</h4>
          </div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={40}
            totalSlides={3}
          >
            <Slider>
              <Slide index={0}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={1}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={2}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </Slider>
            <ButtonBack>
              <img src={leftarrow} />
            </ButtonBack>
            <ButtonNext>
              <img src={rightarrow} />
            </ButtonNext>
          </CarouselProvider>
          <div className="all-location">
            <button type="button" class="btn btn-outline-primary phno">
              {" "}
              View All Collections{" "}
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_504_23861"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="22"
                  height="22"
                >
                  <rect width="22" height="22" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_504_23861)">
                  <path
                    d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                    fill="#F46530"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section className="new-plans">
        <div className="container">
          <div className="top-heading">
            <p className="mb-0">Client Albums</p>
            <h4>Explore our new added Houses</h4>
          </div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={35}
            totalSlides={3}
          >
            <Slider>
              <Slide index={0}>
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="img-banner">
                      <img src={bgimg} />
                      <div className="banner-text">
                        <span>Plan Number</span>
                        <br></br>
                        <h4>#714035BTZ</h4>
                      </div>
                      <div className="banner-lower">
                        <p>
                          Modern Farmhouse Plan 69715AM Comes to Life in
                          Virginia
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={0}>
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="img-banner">
                      <img src={bgimg} />
                      <div className="banner-text">
                        <span>Plan Number</span>
                        <br></br>
                        <h4>#714035BTZ</h4>
                      </div>
                      <div className="banner-lower">
                        <p>
                          Modern Farmhouse Plan 69715AM Comes to Life in
                          Virginia
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={0}>
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="img-banner">
                      <img src={bgimg} />
                      <div className="banner-text">
                        <span>Plan Number</span>
                        <br></br>
                        <h4>#714035BTZ</h4>
                      </div>
                      <div className="banner-lower">
                        <p>
                          Modern Farmhouse Plan 69715AM Comes to Life in
                          Virginia
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </Slider>
            <ButtonBack>
              <img src={leftarrow} />
            </ButtonBack>
            <ButtonNext>
              <img src={rightarrow} />
            </ButtonNext>
          </CarouselProvider>
          <div className="all-location">
            <button type="button" class="btn btn-outline-primary phno">
              {" "}
              View All Collections{" "}
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_504_23861"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="22"
                  height="22"
                >
                  <rect width="22" height="22" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_504_23861)">
                  <path
                    d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                    fill="#F46530"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section className="gallery">
        <div className="top-heading">
          <p className="mb-0">Styles</p>
          <h4>Explore our new added Houses</h4>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="img-wrap">
              <img src={imggal1} />
              <div class="overlay"></div>
              <div className="img-text">
                <p>Italian Kitchen</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="img-wrap">
              <img src={imggal2} />
              <div class="overlay"></div>
              <div className="img-text">
                <p>Italian Kitchen</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="img-wrap">
              <img src={imggal3} />
              <div class="overlay"></div>
              <div className="img-text">
                <p>Italian Kitchen</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="img-wrap">
              <img src={imggal4} />
              <div class="overlay"></div>
              <div className="img-text">
                <p>Italian Kitchen</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img-wrap">
              <img src={imggal5} />
              <div class="overlay"></div>
              <div className="img-text">
                <p>Italian Kitchen</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img-wrap">
              <img src={imggal6} />
              <div class="overlay"></div>
              <div className="img-text">
                <p>Italian Kitchen</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img-wrap">
              <img src={imggal7} />
              <div class="overlay"></div>
              <div className="img-text">
                <p>Italian Kitchen</p>
              </div>
            </div>
          </div>
        </div>
        <div className="all-location">
          <button type="button" class="btn btn-outline-primary phno">
            {" "}
            View All Collections{" "}
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_504_23861"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="22"
                height="22"
              >
                <rect width="22" height="22" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_504_23861)">
                <path
                  d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                  fill="#F46530"
                />
              </g>
            </svg>
          </button>
        </div>
      </section>
      <section className="new-plans">
        <div className="container">
          <div className="top-heading">
            <p className="mb-0">Collections</p>
            <h4>Explore our new added Houses</h4>
          </div>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="collections">
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Exclusive</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Exclusive</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Exclusive</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Exclusive</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="collections">
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>New</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Client Photos</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Interactive Tours</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Photo Gallery</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="collections">
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>New</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Bungalow</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Interactive Tours</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Client Photos</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="collections">
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>New</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Client Photos</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Interactive Tours</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
                <div className="coll1">
                  <div>
                    <img src={PenNib} />
                    <span>Photo Gallery</span>
                  </div>
                  <button type="button" class="btn btn-outline-primary">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.86053 6.00013L0.678711 1.81831L1.63667 0.860352L6.77644 6.00013L1.63667 11.1399L0.678711 10.1819L4.86053 6.00013Z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="all-location mt-5">
                <button type="button" class="btn btn-outline-primary phno">
                  {" "}
                  View All Collections{" "}
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_504_23861"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="22"
                      height="22"
                    >
                      <rect width="22" height="22" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_504_23861)">
                      <path
                        d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                        fill="#F46530"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="new-plans bg-white">
        <div className="container">
          <div className="top-heading">
            <p className="mb-0">Trending Plans</p>
            <h4>Explore our new added Houses</h4>
          </div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={40}
            totalSlides={3}
          >
            <Slider>
              <Slide index={0}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={1}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide index={2}>
                <div className="row">
                  <div className="col-lg-4 d-flex justify-content-end">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="wrap">
                      <div className="img-h">
                        <img src={imghouse1} />
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} />
                          </div>
                          <div className="fav">
                            <img src={fav} />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>2369</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>3</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>1</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr></hr>
                          <div className="area">
                            <strong>2</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#714035BTZ</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">$2,095</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </Slider>
            <ButtonBack>
              <img src={leftarrow} />
            </ButtonBack>
            <ButtonNext>
              <img src={rightarrow} />
            </ButtonNext>
          </CarouselProvider>
          <div className="all-location">
            <button type="button" class="btn btn-outline-primary phno">
              {" "}
              View All Collections{" "}
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_504_23861"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="22"
                  height="22"
                >
                  <rect width="22" height="22" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_504_23861)">
                  <path
                    d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                    fill="#F46530"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </section>
      <section className="ourprize">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="left-ban-text">
                <h4>Our Prices are gurantee</h4>
                <p>If you find a better price elsewhere, we will match it and give you an additional 10% off the matched price*. That's our guarantee! Email us or call us to take advantage of this offer.</p>
                <div className="cont-details">
                  <div>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_29_2732" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                        <rect width="22" height="22" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_29_2732)">
                        <path d="M17.8199 18.7918C16.0924 18.7918 14.357 18.3902 12.6135 17.5869C10.8701 16.7836 9.26687 15.6504 7.80372 14.1874C6.34652 12.7243 5.21627 11.1224 4.41297 9.38179C3.60966 7.64134 3.20801 5.90739 3.20801 4.17993C3.20801 3.90493 3.29967 3.67424 3.48301 3.48785C3.66634 3.30161 3.89551 3.2085 4.17051 3.2085H7.16022C7.39167 3.2085 7.59586 3.28405 7.77278 3.43514C7.94969 3.58609 8.06222 3.77263 8.11034 3.99477L8.63582 6.69183C8.67218 6.94208 8.66454 7.15711 8.6129 7.33693C8.56111 7.51675 8.46822 7.66777 8.33424 7.79L6.21719 9.85089C6.55789 10.4748 6.94717 11.0651 7.38503 11.6217C7.82274 12.1781 8.29665 12.7095 8.80678 13.216C9.30972 13.7191 9.84444 14.1863 10.4109 14.6176C10.9774 15.0488 11.5892 15.4502 12.2461 15.8216L14.3031 13.7467C14.4466 13.5975 14.6203 13.4928 14.8242 13.4328C15.028 13.3729 15.2399 13.3582 15.4597 13.3888L18.0051 13.9071C18.2365 13.9683 18.4254 14.0863 18.5718 14.2614C18.7182 14.4365 18.7913 14.6351 18.7913 14.8573V17.8293C18.7913 18.1043 18.6982 18.3335 18.512 18.5168C18.3256 18.7002 18.0949 18.7918 17.8199 18.7918Z" fill="black" />
                      </g>
                    </svg>
                    <span>+1 (999) 999-99-99</span>
                  </div>
                  <div>
                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_29_2736" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="22">
                        <rect x="0.5" width="22" height="22" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_29_2736)">
                        <path d="M4.4491 17.875C3.98603 17.875 3.59408 17.7146 3.27324 17.3937C2.95241 17.0729 2.79199 16.681 2.79199 16.2179V5.7821C2.79199 5.31903 2.95241 4.92708 3.27324 4.60625C3.59408 4.28542 3.98603 4.125 4.4491 4.125H18.5516C19.0146 4.125 19.4066 4.28542 19.7274 4.60625C20.0482 4.92708 20.2087 5.31903 20.2087 5.7821V16.2179C20.2087 16.681 20.0482 17.0729 19.7274 17.3937C19.4066 17.7146 19.0146 17.875 18.5516 17.875H4.4491ZM11.5003 11.5113L18.8337 6.82206L18.6927 5.5L11.5003 10.0833L4.30793 5.5L4.16699 6.82206L11.5003 11.5113Z" fill="black" />
                      </g>
                    </svg>
                    <span>hello@logoipsum.com</span>
                  </div>

                </div>
                <div className="btm-butn">
                  <button type="button" class="btn btn-primary">
                    Contact Us
                    <img src={btnarrow} />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={btmsvg} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
