import React from 'react'
import Layout from './Layout'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import favimg from "../assets/images/favimg.png"
import editfav from "../assets/images/edit-fav.svg"
import delfav from "../assets/images/delfav.svg"
import { Link } from 'react-router-dom';

const Favorite = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showedit, setShowedit] = useState(false);

    const handleCloseedit = () => setShowedit(false);
    const handleShowedit = () => setShowedit(true);

    const [showdel, setShowdel] = useState(false);

    const handleClosedel = () => setShowdel(false);
    const handleShowdel = () => setShowdel(true);

    return (
        <>
            <Layout>
                <section className='favourite'>
                    <div className='container'>
                        <div className='top-title'>
                            <h2>Favorites</h2>
                            <button type="button" class="btn btn-outline-primary" onClick={handleShow}>Create New Collection</button>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <Link to="/favoritedetails" className='custom-link'>
                                    <div className='p-2'>
                                        <div className='fav-category'>
                                            <img src={favimg} />
                                            <div className='cat-content'>
                                                <div>
                                                    <p>My Favourites</p>
                                                    <span>24 Collections</span>
                                                </div>
                                                <div>
                                                    <img src={editfav} onClick={handleShowedit} />
                                                    <img src={delfav} onClick={handleShowdel} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4'>
                                <Link to="/favoritedetails" className='custom-link'>
                                    <div className='p-2'>
                                        <div className='fav-category'>
                                            <img src={favimg} />
                                            <div className='cat-content'>
                                                <div>
                                                    <p>My Favourites</p>
                                                    <span>24 Collections</span>
                                                </div>
                                                <div>
                                                    <img src={editfav} />
                                                    <img src={delfav} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-lg-4'>
                                <Link to="/favoritedetails" className='custom-link'>
                                    <div className='p-2'>
                                        <div className='fav-category'>
                                            <img src={favimg} />
                                            <div className='cat-content'>
                                                <div>
                                                    <p>My Favourites</p>
                                                    <span>24 Collections</span>
                                                </div>
                                                <div>
                                                    <img src={editfav} />
                                                    <img src={delfav} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add to Favourite</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='fav-list'>
                                <div className='fav-cover'>
                                    <p>My Favourites</p>
                                    <span>124 Collections</span>
                                </div>
                                <div className='fav-cover'>
                                    <p>My Favourites</p>
                                    <span>124 Collections</span>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                Add New Collection
                            </Button>
                            <Button variant="outline-secondary" onClick={handleClose}>
                                Done
                            </Button>


                        </Modal.Footer>
                    </Modal>
                    <Modal show={showedit} onHide={handleCloseedit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Name</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='edit-fav'>
                                <div class="mb-3">

                                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Requester Name" />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleCloseedit}>
                                Submit
                            </Button>
                            <Button variant="outline-secondary" onClick={handleCloseedit}>
                                Cancel
                            </Button>


                        </Modal.Footer>
                    </Modal>
                    <Modal show={showdel} onHide={handleClosedel} className="del">
                        <Modal.Header closeButton>
                            <Modal.Title>Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='edit-fav'>
                                <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="48" height="48" rx="8" fill="#F46530" fill-opacity="0.1" />
                                    <mask id="mask0_194_32135" maskUnits="userSpaceOnUse" x="7" y="8" width="32" height="32">
                                        <rect x="7" y="8" width="32" height="32" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_194_32135)">
                                        <path d="M16.7437 35.3359C16.0788 35.3359 15.5108 35.1005 15.0397 34.6296C14.5688 34.1585 14.3333 33.5905 14.3333 32.9256V16.0026H13V14.0026H19V12.8232H27V14.0026H33V16.0026H31.6667V32.9256C31.6667 33.5991 31.4333 34.1692 30.9667 34.6359C30.5 35.1026 29.9299 35.3359 29.2563 35.3359H16.7437ZM29.6667 16.0026H16.3333V32.9256C16.3333 33.0454 16.3718 33.1437 16.4487 33.2206C16.5256 33.2975 16.6239 33.3359 16.7437 33.3359H29.2563C29.359 33.3359 29.453 33.2931 29.5383 33.2076C29.6239 33.1222 29.6667 33.0282 29.6667 32.9256V16.0026ZM19.5387 30.6692H21.5383V18.6692H19.5387V30.6692ZM24.4617 30.6692H26.4613V18.6692H24.4617V30.6692Z" fill="#F46530" />
                                    </g>
                                </svg>
                                <div class="mb-3 mt-3">
                                    <p>Are you sure want to delete ?</p>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClosedel}>
                                Submit
                            </Button>
                            <Button variant="outline-secondary" onClick={handleClosedel}>
                                Cancel
                            </Button>


                        </Modal.Footer>
                    </Modal>
                </section>
            </Layout>
        </>
    )
}

export default Favorite
