import { createSlice } from "@reduxjs/toolkit";
import { loginAction } from "../actions/AuthAction";
const initialState = {
    authData: {},
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAction.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                state.loading = false;
                state.authData = action?.payload?.data;
                // state.exampleData.push(action.payload);
            })
            .addCase(loginAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default authSlice.reducer;