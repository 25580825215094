import React from 'react'
import Layout from './Layout'

const CopyRight = () => {
    return (
        <>
        <Layout>
            <section className='copyright mt-4'>
                <div className='container'>
                    <div className='top-heading'>
                        <h2>Copyright Notice</h2>

                    </div>
                    <div className='notice'>
                        <p>All the plans in this web site are protected under the Federal Copyright Act, Title XVII of the U.S. Code and Chapter 37 of the Code of Federal Regulations. The owner of the copyright retains title and ownership of the original documents. The blueprints licensed to you cannot be used by or resold to any other individual.</p>
                        <p>Reproduction of these home plans, either in whole or in part, including any form of copying and/or preparation of derivative works thereof, for any reason without prior written permission, is strictly prohibited. The purchase of a set of home plans in no way transfers any copyright or other ownership interest in it to the buyer except for a limited license to use that set of home plans for the construction of one dwelling. The purchase of additional sets of that home plan at a reduced price from the original set or as part of a multiple set package does not convey to the buyer a license to construct more than one dwelling. Similarly, the purchase of reproducible sets carries the same copyright protection. To use any plans more than once, and to avoid any copyright /license infringement, it is necessary to contact the plan's designer to receive a release and license for any extended usage. Whereas a purchaser of PDFs, CAD and Reproducibles is granted a license to make copies, it should be noted that as copyrighted material, making photocopies from blueprints is illegal.</p>
                        <p>Copyright and licensing of home plans for construction exist to protect all parties. It respects and supports the intellectual property of the original architect or designer. Copyright law has been reinforced over the past few years. Willful infringement could cause settlements for statutory damages up to $150,000 plus attorney fees, damages and loss of profits.</p>
                    </div>
                </div>
            </section>
        </Layout>
        </>
    )
}

export default CopyRight
